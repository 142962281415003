<template>
  <div>
    <div class="table-operator">
      <a-button type="primary" icon="plus" @click="handleAdd">新建</a-button>
    </div>

    <s-table
      ref="table"
      size="small"
      :loading="loading"
      :columns="columns"
      :data="loadData"
      rowKey="id"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handleGroup(record)">关联商品</a>
          <a-divider type="vertical" />
          <a-popconfirm
            title="确定要删除么？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="handleDelete(record)"
          >
            <a>删除</a>
          </a-popconfirm>
          <a-divider type="vertical" />
          <a @click="handleEdit(record)">编辑</a>
        </template>
      </span>
    </s-table>
    <create-form
      ref="createModal"
      v-if="visible"
      :visible="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
    <a-modal v-model="previewvisible" title="预览" width="350px" :footer="null">
      <img alt="图片错误" style="width: 300px; margin: 0 auto" :src="previewurl" />
    </a-modal>
  </div>
</template>

<script>
import { STable } from '@/components'
import CreateForm from '@/views/a-applet/wx_goods_group/CreateForm'

import { listSpecificationsGroup, createSpecificationsGroup, deleteSpecificationsGroup, putSpecificationsGroup } from '@/api/applet_goods_group_manage'

export default {
  name: 'TableList',
  components: {
    STable,
    CreateForm
  },
  data () {
    return {
      loading: false,
      confirmLoading: true,
      // 创建窗口控制
      visible: false,
      previewvisible: false,
      previewurl: '',
      mdl: {},
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: '100px',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '名称',
          dataIndex: 'group_name',
          ellipsis: true
        },
        {
          title: '创建人',
          dataIndex: 'create_by',
          ellipsis: true
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '180px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return listSpecificationsGroup(Object.assign(parameter, this.queryParam))
          .then(res => {
            return res.data
          })
      },
      selectedRowKeys: [],
      selectedRows: [],

      // custom table alert & rowSelection
      options: {
        alert: {
          show: true,
          clear: () => {
            this.selectedRowKeys = []
          }
        },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      },
      optionAlertShow: false
    }
  },
  created () {
    this.tableOption()
  },
  methods: {
    tableOption () {
      if (!this.optionAlertShow) {
        this.options = {
          alert: {
            show: true,
            clear: () => {
              this.selectedRowKeys = []
            }
          },
          rowSelection: {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange
          }
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null
        }
        this.optionAlertShow = false
      }
    },
    handleAdd () {
      this.mdl = null
      this.visible = true
      this.confirmLoading = false
    },
    handleAddCancel () {
      this.visible = false

      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleGroup (record) {
      // this.$emit('onEdit', record)
      this.$router.push({ path: '/applet_goods/group/' + record.id + '/goods', query: { name: record.group_name } })
    },
    handleEdit (record) {
      console.log('handleEdit', record)
      this.mdl = record
      this.visible = true
      this.confirmLoading = false
    },
    handleDelete (record) {
      deleteSpecificationsGroup(record.id).then((res) => {
        console.log(res)
        console.log('删除成功------')
        this.$refs.table.refresh()
      })
    },
    handleOk () {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          if (this.mdl) {
            putSpecificationsGroup(values, this.mdl.id).then((res) => {
              console.log(res, '修改成功')
              this.visible = false
              this.confirmLoading = false
              this.$refs.table.refresh()
            })
          } else {
            createSpecificationsGroup(values).then((res) => {
              console.log(res, '创建成功')
              this.visible = false
              this.confirmLoading = false
              this.$refs.table.refresh()
            })
          }
        } else {
          console.log('values---000', errors)
          this.confirmLoading = false
        }
      })
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    getFileSuffix: function (filename) {
      if (filename === undefined || filename === '') {
        return ''
      }
      return filename.substring(filename.lastIndexOf('.') + 1).toLowerCase()
    },
    openPreview (url) {
      if (url === undefined || url === '') {
        return
      }
      this.previewvisible = true
      this.previewurl = url
    }
  }
}
</script>
