<template>
  <div>
    <a-spin :spinning="spinning">
      <a-form :form="form">
        <a-form-item label="名称" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input v-decorator="['name', {rules: [{required: true, message: '请输入'}]}]" />
        </a-form-item>
        <a-form-item label="图片" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <div class="clearfix" style="display: inline-block">
            <a-form-item label="图片" v-show="false">
              <a-textarea v-decorator="['picture_url', {rules: [{required: false, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
            <a-upload
              v-decorator="['picture_url2',{rules: [{required: true, message: '必填项，请填写信息'}]}]"
              name="file"
              accept="image/png, image/jpeg, image/jpg"
              :action="actionUrl"
              list-type="picture-card"
              :file-list="fileList"
              :data="new_multipart_params"
              :before-upload="beforeUpload"
              @preview="handlePreview"
              @change="handleChange"
            >
              <div v-if="fileList.length < 1">
                <a-icon type="plus" />
                <div class="ant-upload-text">
                  修改图片上传
                </div>
              </div>
            </a-upload>
            <span>提示：图片宽高比为1:1</span>
            <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
              <img alt="example" style="width: 100%" :src="previewImage" />
            </a-modal>
          </div>
        </a-form-item>
        <a-form-item label="连接" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input v-decorator="['link', {rules: [{required: false, message: '请输入'}]}]" />
        </a-form-item>
        <a-form-item label="位置" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input-number
            :max="10000"
            :min="1"
            v-decorator="['position', {rules: [{ required: false, message: '请输入' }]}]" />
        </a-form-item>
        <a-form-item label="是否启用" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-switch v-decorator="['enabled', { valuePropName: 'checked' }]" />
        </a-form-item>
        <a-form-item
          v-bind="buttonCol"
        >
          <a-row>
            <a-col span="6">
              <a-button type="primary" html-type="submit" @click="handleSubmit">提交</a-button>
            </a-col>
            <a-col span="10">
              <a-button @click="handleGoBack">返回</a-button>
            </a-col>
            <a-col span="8"></a-col>
          </a-row>
        </a-form-item>
      </a-form>
    </a-spin>
  </div>
</template>

<script>
import pick from 'lodash.pick'
import { oss_token_list } from '@/api/oss_token'
const { editBanner } = require('@/api/applet_banner')
const fields = ['name', 'link', 'picture_url', 'position', 'enabled']
function getBase64 (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}
export default {
  name: 'GoodsEdit',
  props: {
    record: {
      type: [Object, String],
      default: ''
    }
  },
  data () {
    return {
      labelCol: {
        xs: { span: 6 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 }
      },
      buttonCol: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12, offset: 5 }
        }
      },
      form: this.$form.createForm(this),
      id: 0,
      spinning: true,
      previewVisible: false,
      previewVisible2: false,
      actionUrl: '',
      fileList: [],
      fileList2: [],
      parentCategories: [],
      previewImage: '',
      previewImage2: '',
      mysort_order: '1',
      new_multipart_params: {
        'key': '',
        'policy': '',
        'OSSAccessKeyId': '',
        'success_action_status': '200',
        'signature': ''
      }
    }
  },
  beforeCreate () {
    oss_token_list().then(({ data }) => {
      console.log(data, '我是返回值')
      this.new_multipart_params.OSSAccessKeyId = data.accessid
      this.new_multipart_params.policy = data.policy
      this.new_multipart_params.signature = data.signature
      this.actionUrl = 'https://static.jnzbtz.com'
    })
  },
  mounted () {
    this.$nextTick(() => {
      this.loadEditInfo(this.record)
    })
  },
  methods: {
    loadEditInfo (data) {
      const { form } = this
      const formData = pick(data, fields)
      console.log('formData', formData)
      this.id = formData.id
      this.fileList = formData.picture_url
        ? [{
          url: formData.picture_url,
          uid: '1',
          name: 'xxx.png',
          status: 'done',
          response: 'Server Error 500'
        }]
        : []
      form.setFieldsValue(formData)
      this.spinning = false
    },
    beforeUpload (file) {
      this.new_multipart_params.key = file.uid + '_' + file.name
      this.form.setFieldsValue({ picture_url: this.new_multipart_params.key })
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng) {
        this.$message.error('你只能上传jpeg/png格式的图片')
      }
      const isLt2M = file.size / 1024 / 1024 < 5
      if (!isLt2M) {
        this.$message.error('文件过大，请重新上传')
      }
      return isJpgOrPng && isLt2M
    },
    // 进口图片
    async handlePreview (file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj, () => {})
      }
      console.log('我要预览', file)
      this.previewImage = file.url || file.preview || file.thumbUrl
      this.previewVisible = true
    },
    handleChange ({ fileList, file }) {
      this.fileList = fileList
      console.log('我是图片', file)
      if (file.status === 'removed') {
        this.form.setFieldsValue({ picture_url: '' })
        this.$message.error('请重新上传')
      }
      if (file.status === 'done') {
        this.form.setFieldsValue({ picture_url: this.actionUrl + '/' + this.new_multipart_params.key })
      }
    },
    handleCancel () {
      this.previewVisible = false
    },
    handleSubmit () {
      const { form: { validateFields } } = this
      validateFields((err, values) => {
        console.log('我要提交接口', values)
        if (!err) {
          // eslint-disable-next-line no-console
          console.log('Received values of form: ', values)
        }
        editBanner(values, this.record.id).then((res) => {
          this.handleGoBack()
          console.log(res, '修改成功')
        })
      })
    },
    handleGoBack () {
      this.$emit('onGoBack')
    }
  }
}
</script>
