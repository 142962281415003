<template>
  <a-modal
    title="新建"
    :width="480"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form">
        <a-form-item label="名称" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input v-decorator="['group_name', {rules: [{required: true, message: '请输入'}]}]" />
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>
<script>
import pick from 'lodash.pick'
export default {
  name: 'GoodsGroupCreate',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: [Object, String],
      default: ''
    }
  },
  data () {
    return {
      labelCol: {
        xs: { span: 6 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 }
      },
      buttonCol: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12, offset: 5 }
        }
      },
      form: this.$form.createForm(this),
      id: 0
    }
  },
  mounted () {
    this.$nextTick(() => {
      console.log('mounted', this.model)
      this.loadEditInfo(this.model)
    })
  },
  methods: {
    loadEditInfo (data) {
      const { form } = this
      const formData = pick(data, ['group_name'])
      console.log('formData', formData)
      this.id = formData.id
      form.setFieldsValue(formData)
      this.loading = false
    }
  }
}
</script>
