import request from '@/utils/request'

export function listBanner (query) {
  return request({
    url: '/shop/back_banner/',
    method: 'get',
    params: query
  })
}

export function editBanner (parameter, id) {
  return request({
    url: '/shop/back_banner/' + id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

export function addParentCategory (parameter) {
  return request({
    url: '/shop/back_banner/',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
export function deleteBanner (parentId) {
  return request({
    url: '/shop/back_banner/' + parentId + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function deleteChildCategory (id) {
  return request({
    url: '/shop/category/' + id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function createBanner (parameter) {
  return request({
    url: '/shop/back_banner/',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

export function parentCategoryName () {
  return request({
    url: '/shop/back_banner/',
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function detailGoods (id) {
  return request({
    url: '/goods/detail/',
    method: 'get',
    params: { id }
  })
}

export function editGoods (data) {
  return request({
    url: '/shop_goods/',
    method: 'put',
    data
  })
}

export function listCatAndBrand () {
  return request({
    url: '/goods/catAndBrand',
    method: 'get'
  })
}
